import React, { useEffect, useState } from "react";
import { addFile } from "../../services/Storage";

import OrderConstant from "../../constants/OrderConstant";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { inArray, inArrayValue, unCheckInArray } from "../../utilities/array";
import { calculateOrder } from "../../utilities/calculate";

import { ReactComponent as SvgCalendar } from "../../assets/images/deal/calendar.svg";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { ReactComponent as SvgInfo } from "../../assets/images/deal/info.svg";
import { ReactComponent as SvgCube } from "../../assets/images/deal/cube.svg";
import { ReactComponent as SvgPeople } from "../../assets/images/deal/people.svg";

import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";

import UiDropDown from "../inputs/UiDropDown";
import UiTextArea from "../inputs/UiTextArea";
import UiSwitch from "../switch/UiSwitch";
import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiInputTimeRange from "../inputs/UiInputTimeRange";
import UiButtonColor from "../button/UiButtonColor";

import Env from "../../services/Env";

function UiModalDealAdd(props) {
  const volumeArray = OrderConstant.VOLUME_ARRAY;
  const loadArray = OrderConstant.TRASH_ARRAY;
  const typeCarArray = OrderConstant.CAR_TYPE_ARRAY;

  const trashArray = OrderConstant.TRASH_ARRAY;
  const takArray = OrderConstant.TAK_ARRAY;
  const unbuildArray = OrderConstant.UNBUILD_ARRAY;
  const packArray = OrderConstant.PACK_ARRAY;
  const liftTypes = OrderConstant.LIFT_TYPES;
  const instrumentsArray = OrderConstant.INSTRUMENT_ARRAY;

  const moving_types = OrderConstant.MOVING_TYPE;
  const prr_types = OrderConstant.PRR_TYPE;

  const parkingHeight = OrderConstant.PARKING_HEIGHT;

  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

  const [priceList, setPriceList] = useState(props.prices);

  const [order, setOrder] = useState({
    type: 0,
    order_type: 0,
    order_name: null,
    user_id: 0,
    region_id: 1,
    user_name: null,
    manual_select_worker: 0,

    //step1
    address_a: "",
    address_b: "",
    elevator_type_a: 0,
    elevator_type_b: 0,
    address_a_city: null,
    address_b_city: null,

    address_list: [
      {
        address: "",
        elevator_type: 0,
        floor: "",
      },
      {
        address: "",
        elevator_type: 0,
        floor: "",
      },
    ],

    floor_a: "",
    floor_b: "",
    date: new Date(),
    time: null,
    date_dmy: null,
    time_hm: null,
    //step2
    moving_types: 0,
    prr_types: 0,
    volume_car: 0,
    type_car: 0,
    load_car: 0,

    movers: 0,
    additional_work: [],
    tak_value: 0,
    unbuild_value: 0,
    pack_value: [],
    instrument_value: [],
    trash_value: 0,

    parking_value: 0,
    demolition: 0,
    without_car: 0,

    photos: [],
    text: "",

    distance: 0,
    price: 0,
  });

  function uploadList(file) {
    setLoad(true);
    addFile(file, 2).then((res) => {
      console.log(res);
      setLoad(false);
      if (res.success) {
        setImage(res.response);
        setImageUrl(Env.PUBLIC_URL + res.response);
      }
    });
  }

  function init() {
    document.getElementById("maps").innerHTML =
      '<div id="map" style="width: 100%; height: 300px;"></div>';
    var DELIVERY_TARIFF = 20,
      MINIMUM_COST = 500,
      myMap = new window.ymaps.Map("map", {
        center: [60.906882, 30.067233],
        zoom: 9,
        controls: [],
      }),
      zoomControl = new window.ymaps.control.ZoomControl({
        options: {
          size: "small",
          float: "none",
          position: {
            bottom: 145,
            right: 10,
          },
        },
      });

    var multiRoute = new window.ymaps.multiRouter.MultiRoute(
      {
        referencePoints: order.address_list.map((item) => {
          return item.address;
        }),
        params: {},
      },
      {
        boundsAutoApply: true,
      }
    );

    multiRoute.model.events.add("requestsuccess", () => {
      // Получение ссылки на активный маршрут.
      var activeRoute = multiRoute.getActiveRoute();
      // Вывод информации о маршруте.
      console.log("Длина: " + activeRoute.properties.get("distance").text);
      console.log(
        "Время прохождения: " + activeRoute.properties.get("duration").text
      );
      calculate(
        Math.round(activeRoute.properties.get("distance").value / 1000)
      );
      // Для автомобильных маршрутов можно вывести
      // информацию о перекрытых участках.
      if (activeRoute.properties.get("blocked")) {
        alert("На маршруте имеются участки с перекрытыми дорогами.");
      }
    });
    // Добавление маршрута на карту.
    myMap.geoObjects.add(multiRoute).add(zoomControl);

    function calculate(routeLength) {
      console.log("distance", routeLength);
      updateOrderObj("distance", routeLength);
    }
  }

  /**
   * Update price
   * @param {string} _title
   * @param {any} _val
   */
  function updateByKey(_title, _val, _index = null){
    let _set = priceList;
    if(_index) _set[_title][_index] = parseFloat(_val); else  _set[_title] = parseFloat(_val);
    setPriceList(_set);

    let _obj = order;
    _obj["price"] = calculateOrder(_set, order);
    setOrder({ ..._obj });
 
  }

  /**
   * Render price setting 
   * @returns 
   */

  function renderPrices(_priceList) {
    _priceList = [_priceList];
    return _priceList.map((rItem, rIndex) => {
      if (rItem) {
        let price_volume_input = rItem.volume_car.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.volume_car[index]}
              label={`Объем машины ${volumeArray[index].text} `}
              onBlur={(val) => {
                updateByKey(`volume_car`, val, index);
              }}
            />
          );
        });
        let price_movers_input = rItem.movers.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.movers[index]}
              label={`Грузчики - ${index} чел`}
              onBlur={(val) => {
                updateByKey(`movers`, val, index);
              }}
            />
          );
        });

        let prr_types_input = rItem.prr_types.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.prr_types[index]}
              label={`ПРР - ${prr_types[index].text}`}
              onBlur={(val) => {
                updateByKey(`prr_types`, val, index);
              }}
            />
          );
        });

        let trash_value_input = rItem.trash_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.trash_value[index]}
              label={`Мусор - ${trashArray[index].text}`}
              onBlur={(val) => {
                updateByKey(`trash_value`, val, index);
              }}
            />
          );
        });
        let tak_value_input = rItem.tak_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.tak_value[index]}
              label={`Такелаж - ${takArray[index].text}`}
              onBlur={(val) => {
                updateByKey(`tak_value`, val, index);
              }}
            />
          );
        });
        let moving_types_input = rItem.moving_types.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.moving_types[index]}
              label={`Тип - ${moving_types[index].text}`}
              onBlur={(val) => {
                updateByKey(`moving_types`, val, index);
              }}
            />
          );
        });
        let unbuild_value_input = rItem.unbuild_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.unbuild_value[index]}
              label={`Сборка/разборка - ${unbuildArray[index].text}`}
              onBlur={(val) => {
                updateByKey(`unbuild_value`, val, index);
              }}
            />
          );
        });
        let pack_value_input = rItem.pack_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.pack_value[index]}
              label={`Тип - ${packArray[index].text}`}
              onBlur={(val) => {
                updateByKey(`pack_value`, val, index);
              }}
            />
          );
        });

        return (
          <section className="page-content setting-forms" key={rIndex}>
            <h3>
              Настройки цены{" "}
            
            </h3>
            <h4>Настройка объема машины</h4>
            <div className="setting-forms-rows">{price_volume_input}</div>
            <h4>Настройка грузчиков</h4>
            <div className="setting-forms-rows">{price_movers_input}</div>

            <h4>Дополнительные настройки 1</h4>
            <div className="setting-forms-rows">
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.movers_additional}
                  label={`Цена за доп. грузчика`}
                  onBlur={(val) => {
                    updateByKey(`movers_additional`, val);
                  }}
                />
              ) : null}
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.distance_km}
                  label={`Цена за км пути`}
                  onBlur={(val) => {
                    console.log(val)
                    updateByKey(`distance_km`, val);
                  }}
                />
              ) : null}
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.distance_less_25_km}
                  label={`Цена за км пути если расстояние меньше 25 км`}
                  onBlur={(val) => {
                    updateByKey(`distance_less_25_km`, val);
                  }}
                />
              ) : null}
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.distance_then_25_km}
                  label={`Цена за км пути если расстояние больше 25 км`}
                  onBlur={(val) => {
                    updateByKey(`distance_then_25_km`, val);
                  }}
                />
              ) : null}
            </div>
            <h4>Дополнительные настройки 2</h4>
            <div className="setting-forms-rows">
              {prr_types_input}
              {trash_value_input}
              {tak_value_input}
              {moving_types_input}
              {unbuild_value_input}
              {pack_value_input}
            </div>
          </section>
        );
      }
    });
  }


  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    if (val != "price") {
      _obj["price"] = calculateOrder(priceList, order);
    }
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  /**
   *
   * @param {int} index
   * @param {string} key
   * @param {any} val
   */
  function updateAddress(index, key, val) {
    var arr = JSON.parse(JSON.stringify(order.address_list));
    arr[index][key] = val;
    updateOrderObj("address_list", arr);
  }

  /**
   *
   * @param {array} _points
   * @returns {boolean}
   */
  function checkPoints(_points) {
    let f = true;
    _points.map((item) => {
      if (item.address == ""  ) f = false;
    });
    return f;
  }

  useEffect(() => {
    if(props.prices) setPriceList(props.prices);
  }, [props.modalActive])
 

  return (
    <UiModal
      name="modal"
      show={props.modalActive}
      onClose={() => props.modalClose()}
    >
      <div className="modal-deal modal-overlay">
        <div className="modal-deal-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>Создать заказ</h3>
              </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">
                  <div className="modal-deal-info-form">
                    <UiSwitch
                      label="ОТКЛЮЧИТЬ АВТО ВЫБОР ИСПОЛНИТЕЛЯ"
                      value={order.manual_select_worker}
                      onChange={(val) => {
                        updateOrderObj("manual_select_worker", val ? 1 : 0);
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Название заказа:"
                      placeholder="Название заказа (не обязательно)"
                      onChange={(val) => {
                        updateOrderObj("order_name", val);
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiTextInputMasked
                      label="Телефон заказчика:"
                      placeholder="Введите Телефон.."
                      onChange={(val) => {
                        var str = val.replace(/[^0-9]/g, "");
                        updateOrderObj("user_phone", str);
                      }}
                      onBlur={(val) => {
                        var str = val.replace(/[^0-9]/g, "");
                        props.findUserEvent(str).then((res) => {
                          if (res) updateOrderObj("user_name", res.name);
                        });
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Имя заказчика:"
                      placeholder="Введите Имя заказчика.."
                      value={order.user_name}
                      onChange={(val) => {
                        updateOrderObj("user_name", val);
                      }}
                    />
                  </div>

                  {order.address_list.map((aItem, aIndex) => {
                    return (
                      <div key={aIndex} className="modal-deal-points-wrap">
                        <div className="modal-deal-info-form">
                          <UiInputText
                            label={`Адрес пункта ${
                              ["A", "Б", "В", "Г", "Д"][aIndex]
                            }:`}
                            placeholder="Введите адрес.."
                            onChange={(val) =>
                              updateAddress(aIndex, "address", val)
                            }
                            onBlur={() => {
                              if (window.ymaps) window.ymaps.ready(init);
                            }}
                          />
                        </div>
                        <div className="modal-deal-info-form">
                          <UiDropDown
                            label={`Наличие лифта в пункте ${
                              ["A", "Б", "В", "Г", "Д"][aIndex]
                            }:`}
                            items={liftTypes}
                            onChange={(val) =>
                              updateAddress(aIndex, "elevator_type", val)
                            }
                          />
                        </div>
                        <div className="modal-deal-info-form">
                          <UiInputText
                            label={`Этаж ${["A", "Б", "В", "Г", "Д"][aIndex]}`}
                            placeholder="Этаж.."
                            onChange={(val) =>
                              updateAddress(aIndex, "floor", val)
                            }
                          />
                        </div>

                        {aIndex > 1 && (
                          <UiButtonColor
                            color="orange"
                            onClick={() => {
                              var arr = JSON.parse(
                                JSON.stringify(order.address_list)
                              );
                              arr.splice(aIndex, 1);
                              updateOrderObj("address_list", arr);
                            }}
                            text="Удалить точку"
                          />
                        )}
                      </div>
                    );
                  })}

                  {order.address_list.length < 8 && (
                    <UiButtonColor
                      color="blue"
                      onClick={() => {
                        let arr = order.address_list;
                        arr.push({
                          address: "",
                          elevator_type: 0,
                          floor: "",
                        });

                        updateOrderObj("address_list", arr);
                      }}
                      text="Добавить точку +"
                    />
                  )}

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Дата заказа"
                      type={"date"}
                      onChange={(val) => {
                        console.log(val);
                        updateOrderObj("date", val);
                        updateOrderObj("date_dmy", formatDateDMY(val));
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputTimeRange
                      label="Время заказа"
                      onChange={(val) => {

                         console.log(val,  formatDateHM(val) )
                        updateOrderObj("time", val);
                        updateOrderObj("time_hm",  (val));
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <p>
                      <b>Дистанция:</b> {order.distance} км
                    </p>
                    <br />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Цена (рубли):"
                      value={order.price}
                      onChange={(val) => {
                        updateOrderObj("price", val);
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Финальная ставка (рубли):"
                      value={order.worker_bet}
                      onChange={(val) => {
                        updateOrderObj("worker_bet", val);
                      }}
                    />
                  </div>

                  <div className="modal-deal-info-form">
                    <UiButtonColor
                      color="primary"
                      text="Создать"
                      small={false}
                      onClick={() => {
                        console.log(
                          order,
                          checkPoints(order.address_list),
                          order.date_dmy != null,
                          order.time != null
                        );
                        if (
                          checkPoints(order.address_list) &&
                          order.date_dmy != null &&
                          order.time != null
                        ) {
                          let _order = order;
                          //_order.price = calculateOrder(order);
                          props.onCreate(_order);
                        } else {
                          if(!checkPoints(order.address_list)){
                            alert("Поле адрес не заполнен");
                          } else if(order.date_dmy == null){
                            alert("Поле Дата не заполнен");
                          } else if(order.time == null){
                            alert("Поле Время не заполнен");
                          } else {
                            alert("Заполните поля адрес и дату");
                          }
                          
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**
           * modal right side - tabs
           */}
          <div className="modal-deal-info-wrap">
            {/** close button */}
            <button
              className="modal-deal-info-close"
              onClick={() => props.modalClose()}
            >
              <SvgClose className="modal-deal-info-close-icon" />
            </button>
            <div className="modal-deal-info">
              <h3>Услуги, грузчики и объем</h3>
              <div className="modal-deal-info-form">
                <UiDropDown
                  label="Паркинг"
                  items={parkingHeight}
                  onChange={(val) => updateOrderObj("parking_value", val)}
                />

                <UiDropDown
                  label="Погрузочно-разгрузочные работы:"
                  items={prr_types}
                  onChange={(val) => updateOrderObj("prr_types", val)}
                />
                <UiDropDown
                  label="Объем машины, кубатура:"
                  items={volumeArray}
                  onChange={(val) => updateOrderObj("volume_car", val)}
                />
                <UiDropDown
                  label="Тоннаж  машины:"
                  items={loadArray}
                  onChange={(val) => updateOrderObj("load_car", val)}
                />
                <UiDropDown
                  label="Спецтехника:"
                  items={typeCarArray}
                  onChange={(val) => updateOrderObj("type_car", val)}
                />
                <UiInputText
                  label="Необходимые грузчики"
                  placeholder="от 0 до 5 чел."
                  type={"number"}
                  minValue={0}
                  maxValue={5}
                  onChange={(val) => updateOrderObj("movers", val)}
                />

                <UiSwitch
                  label="Демонтаж"
                  onChange={(val) =>
                    updateOrderObj(
                      "demolition",
                      val ? 1 : 0
                    )
                  }
                />

                <UiSwitch
                  label="Только грузчики"
                  onChange={(val) =>
                    updateOrderObj(
                      "without_car",
                      val ? 1 : 0
                    )
                  }
                />

                {order.moving_types != 4 ? (
                  <div className="modal-deal-add-switch">
                    <UiSwitch
                      label="Такелажные работы"
                      onChange={(val) =>
                        updateOrderObj(
                          "additional_work",
                          unCheckInArray(order.additional_work, 0)
                        )
                      }
                    />
                    <UiSwitch
                      label="Сборка и разборка"
                      onChange={(val) =>
                        updateOrderObj(
                          "additional_work",
                          unCheckInArray(order.additional_work, 1)
                        )
                      }
                    />
                    <UiSwitch
                      label="Упаковка и пленка"
                      onChange={(val) =>
                        updateOrderObj(
                          "additional_work",
                          unCheckInArray(order.additional_work, 2)
                        )
                      }
                    />
                  </div>
                ) : null}

                <label>Интсрументы</label>
                {instrumentsArray.map((item, index) => {
                  return (
                    <UiSwitch
                      key={index}
                      label={item.text}
                      onChange={(val) => {
                        let arr = order.instrument_value;
                        let i = -1;
                        arr.map((it, id) => {
                          if (it == item.text) i = id;
                        });
                        if (i >= 0) arr.splice(i, 1);
                        else arr.push(item.text);
                        updateOrderObj("instrument_value", arr);
                      }}
                    />
                  );
                })}
                <div>
                  {inArray(order.additional_work, 0) ? (
                    <UiDropDown
                      label={"Вид такелажа"}
                      placeholder="от 1.5 до 45 м3"
                      items={takArray}
                      onChange={(val) => updateOrderObj("tak_value", val)}
                    />
                  ) : null}
                  {inArray(order.additional_work, 1) ? (
                    <UiDropDown
                      label="Вид сборки и разборки"
                      placeholder="от 1.5 до 45 м3"
                      items={unbuildArray}
                      onChange={(val) => updateOrderObj("unbuild_value", val)}
                    />
                  ) : null}
                  {inArray(order.additional_work, 2) && <label>Упаковка</label>}
                  {inArray(order.additional_work, 2)
                    ? packArray.map((item, index) => {
                        return (
                          <UiSwitch
                            key={index}
                            label={item.text}
                            onChange={(val) => {
                              let arr = order.pack_value;
                              let i = -1;
                              arr.map((it, id) => {
                                if (it == item.text) i = id;
                              });
                              if (i >= 0) arr.splice(i, 1);
                              else arr.push(item.text);
                              updateOrderObj("pack_value", arr);
                            }}
                          />
                        );
                      })
                    : null}
                  {order.moving_types == 4 ? (
                    <UiDropDown
                      label="Объем мусора"
                      placeholder="от 1.5 до 45 м3"
                      items={trashArray}
                      onChange={(val) => updateOrderObj("trash_value", val)}
                    />
                  ) : null}
                </div>

                <UiTextArea
                  label="Комментарий:"
                  placeholder="Введите комментарий.."
                  onChange={(val) => {
                    updateOrderObj("text", val);
                  }}
                />

                {renderPrices(priceList)}
              </div>

              <div id="maps"></div>
            </div>
          </div>
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalDealAdd;
