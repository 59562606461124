export default {
  CAR_TYPE_ARRAY: [
    { value: 0, text: "Нет" },
    { value: 1, text: "пирамида" },
    { value: 2, text: "трал" },
    { value: 3, text: "эвакуатор" }, 
    { value: 4, text: "манипулятор" },
  ],
  VOLUME_ARRAY: [
    { value: 0, text: "до 1,5 кубов" },
    { value: 1, text: "от 1,5 до 2,5 кубов" },
    { value: 2, text: "от 7 до 10 кубов" },
    { value: 3, text: "от 10 до 13 кубов" },
    { value: 4, text: "от 13 до 16 кубов" },
    { value: 5, text: "от 16 до 20 кубов" },
    { value: 6, text: "от 20 до 40  кубов" },
    { value: 7, text: "свыше 40 кубов" },
  ],
  TRASH_ARRAY: [
    { value: 0, text: "до 1 тонны " },
    { value: 1, text: "от 1 до 1,5 тонн" },
    { value: 2, text: "от 1,5 до 2 тонн" },
    { value: 3, text: "свыше 2 тонн" },
  ],
  TAK_ARRAY: [
    { value: 0, text: "1 этаж" },
    { value: 1, text: "Такелаж сейфа до 150 кг" },
    { value: 2, text: "Такелаж сейфа до 250 кг" },
    { value: 3, text: "Такелаж сейфа свыше 250 кг" },
    { value: 4, text: "Такелаж сейфа свыше 250 кг" },
  ],
  UNBUILD_ARRAY: [
    { value: 0, text: "до 1 метра" },
    { value: 1, text: "от 1 до 2,5 метра" },
    { value: 2, text: "от 2,5 и выше" },
  ],
  PACK_ARRAY: [
    { value: 0, text: "Стретч пленка" },
    { value: 1, text: "Пупырчатая пленка" },
    { value: 3, text: "Коробки" },
    { value: 4, text: "Ростовые коробки" },
    { value: 5, text: "Жгут" },
    { value: 6, text: "Маркер" },
    { value: 7, text: "Мешки" },
    { value: 8, text: "Ветошь" },
    { value: 9, text: "Пакеты" },

    { value: 9, text: "Картон" },
    { value: 9, text: "Скотч" },
    { value: 9, text: "Поролоновые углы" },

  ],
  INSTRUMENT_ARRAY: [
    { value: 0, text: "Шуруповерт" },
    { value: 1, text: "Перфоратор" },
    { value: 2, text: "Стремянка" },
    { value: 3, text: "Стройматериалы" },
    { value: 4, text: "Набор ключей" },
  ],
  MOVING_TYPE: [
    { value: 0, text: "Офисный" },
    { value: 1, text: "Домашний (коробки)" },
    { value: 2, text: "Домашний (габарит)" },
    { value: 3, text: "Стройматериалы" },
    { value: 4, text: "Вывоз мусора" },
  ],
  PRR_TYPE: [
    { value: 0, text: "Без ПРР" },
    { value: 1, text: "С ПРР" },
  ],
  LIFT_TYPES: [
    { value: 0, text: "Нет" },
    { value: 1, text: "Обычный" },
    { value: 2, text: "Грузовой" },
  ],

  PARKING_HEIGHT: [
    { value: 0, text: "Нет" },
    { value: 1.8, text: "1.8 м" },
    { value: 1.9, text: "1.8 м" },
    { value: 2, text: "2 м" },
    { value: 2.1, text: "2.1 м" },
    { value: 2.2, text: "2.2 м" },
    { value: 2.3, text: "2.3 м" },

  ],

 

 

  ORDER_TYPES_NAMES: [
    "Принятая заявка",
    "Заявка в работу",
    "Подготовка для выгрузки",
    "Отправлены инструкции",
    "Ожидает исполнения",
    "Исполняется ЗАВТРА!",
    "Дальний рейс",
    "Контроль старта(делается сегодня)",
    "Закончен, ожидает оплаты",
    "Ожидают проставления рейтинга",
    "БЕЗНАЛ",
    "Архивация",
  ],
  ORDER_STATUS_NEW: 0,
  ORDER_STATUS_WAIT: 1,
  ORDER_STATUS_SEND: 2,
  ORDER_STATUS_CONTROL: 3,
  ORDER_STATUS_RAIT: 4,
  ORDER_STATUS_DONE: 8,
  ORDER_STATUS_ARCHIVE: 11,

  ORDER_TYPES_COLORS: [
    "purple", "grass", "dark", "blue", "orange", "cyan",
    "purple", "grass", "dark", "blue", "orange", "cyan",
    "purple", "grass", "dark", "blue", "orange", "cyan",
  ],

  UPDATE_INFO_NEW_STATUS: 'new_status',
  UPDATE_INFO_CHANGE_INFO: 'change_info',
  UPDATE_INFO_CHANGE_PRICE: 'change_price',
  UPDATE_INFO_NEW_WORKER: 'new_worker',
  UPDATE_INFO_REMOVE_WORKER: 'remove_worker',
};