import React from "react";
import { connect } from "react-redux";

import UiModal from "./UiModal";

import { ReactComponent as LoaderImage } from "../../assets/images/loader/rolling-1s-200px.svg";

function UiLoader(props) {
  return (
    <UiModal name="loader" show={
      props.login.isFetching  ||
      props.settings.isFetching  ||
      props.order.isFetching
    }>
      <LoaderImage className="loader-img" />
    </UiModal>
  );
}



export default connect(
  ({ login, order, settings }) => ({ login, order , settings  }),
  {}
)(UiLoader);

