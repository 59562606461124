import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPrice as getPriceAction,
  getSortedPriceList as getSortedPriceListAction,
  updateByKey as updateByKeyAction,
  updateMassPrice as updateMassPriceAction,
} from "../../redux/modules/settings";
import { getRegionsList as getRegionsListAction } from "../../redux/modules/users";

import OrderConstant from "../../constants/OrderConstant";

import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";
import UiDropDown from "../../components/inputs/UiDropDown";

import UiButtonColor from "../../components/button/UiButtonColor";

import "./SettingStyle.scss";

function SettingPage(props) {
  const volumeArray = OrderConstant.VOLUME_ARRAY;
  const trashArray = OrderConstant.TRASH_ARRAY;
  const takArray = OrderConstant.TAK_ARRAY;
  const unbuildArray = OrderConstant.UNBUILD_ARRAY;
  const packArray = OrderConstant.PACK_ARRAY;
  const liftTypes = OrderConstant.LIFT_TYPES;
  const moving_types = OrderConstant.MOVING_TYPE;
  const prr_types = OrderConstant.PRR_TYPE;

  const [priceList, setPriceList] = useState([]);
  const [regionList, setRegionList] = useState([]);

  const [massChangeValue, setMassChangeValue] = useState(0);
  const [incValue, setMassIncValue] = useState(0);
  const [region, setRegion] = useState(1);

  useEffect(() => {
    if (props.login.user) {
      props.getSortedPriceList().then((data) => {
        setPriceList(data);
      });
      props.getRegionsList().then((data) => {
        setRegionList(data);
      });
    }
  }, [props.login.user]);

  function updateSettingsPrices() {
    props.updateMassPrice(region, incValue, massChangeValue).then((data) => {
      alert("Операция выполнена");
    });
  }

  function renderPrices() {
    return priceList.map((rItem, rIndex) => {
      if (rItem) {
        let price_volume_input = rItem.volume_car.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.volume_car[index]}
              label={`Объем машины ${volumeArray[index].text} `}
              onBlur={(val) => {
                props.updateByKey(`volume_car_price_${index}`, val);
              }}
            />
          );
        });
        let price_movers_input = rItem.movers.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.movers[index]}
              label={`Грузчики - ${index} чел`}
              onBlur={(val) => {
                props.updateByKey(`movers_price_${index}`, val);
              }}
            />
          );
        });

        let prr_types_input = rItem.prr_types.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.prr_types[index]}
              label={`ПРР - ${prr_types[index].text}`}
              onBlur={(val) => {
                props.updateByKey(`prr_types_price_${index}`, val);
              }}
            />
          );
        });

        let trash_value_input = rItem.trash_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.trash_value[index]}
              label={`Мусор - ${trashArray[index].text}`}
              onBlur={(val) => {
                props.updateByKey(`trash_value_price_${index}`, val);
              }}
            />
          );
        });
        let tak_value_input = rItem.tak_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.tak_value[index]}
              label={`Такелаж - ${takArray[index].text}`}
              onBlur={(val) => {
                props.updateByKey(`tak_value_price_${index}`, val);
              }}
            />
          );
        });
        let moving_types_input = rItem.moving_types.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.moving_types[index]}
              label={`Тип - ${moving_types[index].text}`}
              onBlur={(val) => {
                props.updateByKey(`moving_types_price_${index}`, val);
              }}
            />
          );
        });
        let unbuild_value_input = rItem.unbuild_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.unbuild_value[index]}
              label={`Сборка/разборка - ${unbuildArray[index].text}`}
              onBlur={(val) => {
                props.updateByKey(`unbuild_value_price_${index}`, val);
              }}
            />
          );
        });
        let pack_value_input = rItem.pack_value.map((item, index) => {
          return (
            <UiInputText
              key={index}
              placeholder={rItem.pack_value[index]}
              label={`Тип - ${packArray[index].text}`}
              onBlur={(val) => {
                props.updateByKey(`pack_value_price_${index}`, val);
              }}
            />
          );
        });

        return (
          <section className="page-content setting-forms" key={rIndex}>
            <h3>
              Настройки цены{" "}
              {
                regionList.filter((item) => {
                  return item.id == rIndex;
                })[0].title
              }
            </h3>
            <h4>Настройка объема машины</h4>
            <div className="setting-forms-rows">{price_volume_input}</div>
            <h4>Настройка грузчиков</h4>
            <div className="setting-forms-rows">{price_movers_input}</div>

            <h4>Дополнительные настройки 1</h4>
            <div className="setting-forms-rows">
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.movers_additional}
                  label={`Цена за доп. грузчика`}
                  onBlur={(val) => {
                    props.updateByKey(`movers_additional_price`, val);
                  }}
                />
              ) : null}
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.distance_km}
                  label={`Цена за км пути`}
                  onBlur={(val) => {
                    props.updateByKey(`distance_km_price`, val);
                  }}
                />
              ) : null}
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.distance_less_25_km}
                  label={`Цена за км пути если расстояние меньше 25 км`}
                  onBlur={(val) => {
                    props.updateByKey(`distance_less_25_km_price`, val);
                  }}
                />
              ) : null}
              {rItem.movers_additional ? (
                <UiInputText
                  placeholder={rItem.distance_then_25_km}
                  label={`Цена за км пути если расстояние больше 25 км`}
                  onBlur={(val) => {
                    props.updateByKey(`distance_then_25_km_price`, val);
                  }}
                />
              ) : null}
            </div>
            <h4>Дополнительные настройки 2</h4>
            <div className="setting-forms-rows">
              {prr_types_input}
              {trash_value_input}
              {tak_value_input}
              {moving_types_input}
              {unbuild_value_input}
              {pack_value_input}
            </div>
          </section>
        );
      }
    });
  }

  return (
    <div id="page" className="setting-page">
      <section className="setting-info ui-grid">
        <div className="setting-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>

      <section className="page-content">
        <h3>Изменение цены в регионе от цен в МО</h3>
        <div className="region-settings">
          <h4>В каком регионе изменить</h4>
          <UiDropDown
            items={regionList.map((item) => {
              return { value: item.id, text: item.title };
            })}
            value={region}
            onChange={(val) => setRegion(val)}
          />
          <div className="region-settings-form">
            <UiDropDown
              items={[
                { value: 0, text: "увеличить на" },
                { value: 1, text: "уменьшить на" },
              ]}
              onChange={(val) => setMassIncValue(val)}
            />
            <UiInputText
              value={massChangeValue}
              onChange={(val) => {
                setMassChangeValue(val);
              }}
            />
            <p>%</p>
          </div>
        </div>

        <UiButtonColor
          color="primary"
          text="Изменить цену"
          small={true}
          onClick={() => updateSettingsPrices()}
        />
      </section>
      {renderPrices()}
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getPrice: getPriceAction,
  getSortedPriceList: getSortedPriceListAction,
  updateByKey: updateByKeyAction,
  getRegionsList: getRegionsListAction,
  updateMassPrice: updateMassPriceAction,
})(SettingPage);
