import React, { useState, useEffect } from "react";

import "./UiInputText.scss";

const UiInputTimeRange = (props) => {
  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  useEffect(()=>{
    if(props.value){
        let arr = props.value.replace(/ /g,'').split("-"); 
 
        setT1(arr[0].length == 4 ? `0${arr[0]}`: arr[0])
        if(arr[1]) setT2(arr[1].length == 4 ? `0${arr[1]}`: arr[1]); else setT2(arr[0].length == 4 ? `0${arr[0]}`: arr[0]);
    }
  }, [props.value]);

  return (
    <div className="input-wrap">
      {props.label ? <label>{props.label}</label> : null}
      <input
        className={
          "input-text" 
        } 
        type={"time"}
        value={t1} 
        onChange={(e) => { 
            let val =  e.target.value ; 
            setT1(val);  
        }} 
        onBlur={() =>   { 
           props.onChange(`${t1} - ${t2 != "" ? t2 : t1}`)
        }}
      />
      
      <input
        className={
          "input-text" 
        } 
        type={"time"}
        value={t2} 
        onChange={(e) => { 
            let val =  e.target.value ; 
            setT2(val);  
        }} 
        onBlur={() =>   props.onChange(`${t1} - ${t2}`)}
      />
    </div>
  );
};

export default UiInputTimeRange;
