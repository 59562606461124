import {
    storeData,
    retrieveData,
    safelyParseJSON,
} from "../../services/Storage";
import {
    storeOrder,
    getAllOrders,
    updateUserOrder, doneUserOrder, getFilteredOrders,
    getInvitesOrder, getAllFilteredOrders, removeUserInvite
} from "../../services/Order";
import { filter, addWorkerUser } from "../../services/User";
import OrderConstant from "../../constants/OrderConstant";
import {
    getAdressAutocompleatKladr
} from "../../services/Adress";
import { inArray } from "../../utilities/array";

import { addFile } from "../../services/Storage";

const moduleName = "order";
const SET_ORDER = `${moduleName}/SET_ORDER`;
const SET_USER_ORDERS = `${moduleName}/SET_USER_ORDERS`;
const SET_USER_INVITES = `${moduleName}/SET_USER_INVITES`;
const SET_ORDERS = `${moduleName}/SET_ORDERS`;
const SET_ADDRESS = `${moduleName}/SET_ADDRESS`;

const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
    order: {
        //step1
        address_a: "",
        address_b: "",
        elevator_type_a: 0,
        elevator_type_b: 0,
        address_a_city: null,
        address_b_city: null,

        floor_a: "",
        floor_b: "",
        date: (new Date()),
        time: (new Date()),
        date_dmy: null,
        time_hm: null,
        //step2
        moving_types: 0,
        prr_types: 0,
        volume_car: 0,
        movers: 0,
        additional_work: [],
        tak_value: 0,
        unbuild_value: 0,
        pack_value: 0,
        trash_value: 0,
        photos: [],
        text: "",

        distance: 0,
    },

    ordersUserList: [],
    ordersList: [],
    ordersInvites: [],
    addressList: [],
    isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_ORDER:
            return { ...state, order: payload };
        case SET_USER_ORDERS:
            return { ...state, ordersUserList: payload };

        case SET_USER_INVITES:
            return { ...state, ordersInvites: payload };
        case SET_ORDERS:
            return { ...state, ordersList: payload };
        case SET_ADDRESS:
            return { ...state, addressList: payload };


        case SET_API_REQUEST:
            return { ...state, isFetching: payload };

        default:
            return state;
    }
};

export const findAddress = (_line) => async (dispatch, getState) => {
    try {


        return getAdressAutocompleatKladr(_line).then((res) => {
            //console.log(res.suggestions);

            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_ADDRESS,
                payload: res.suggestions
            });

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}



export const getAllUserOrders = () => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
        }

        return getAllOrders(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_ORDERS,
                payload: res.response.filter((item) => { return item.status != 11 })
            });

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}

export const getFilteredUserOrders = (_key, _value) => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
            filter_key: _key,
            filter_value: _value
        }

        return getFilteredOrders(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_ORDERS,
                    payload: res.response
                });
            }


            return res.response;
        })
    } catch (error) {
        console.error(error);
    }

}

export const getAllFilteredUserOrders = (_val) => async (dispatch, getState) => {
    try {

        let _arr = _val;
        _arr.push(['deals.is_del', '=', 0])
        let  _data = {
            api_token : getState().login.user.api_token,
            filter_key: _arr
        }
 
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return getAllFilteredOrders(_data).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch({
                    type: SET_ORDERS,
                    payload: res.response
                });
            }


            return res.response;
        })
    } catch (error) {
        console.error(error);
    }

}



export const updateOrder = (_data, _info) => async (dispatch, getState) => {
    try {
        _data['api_token'] = getState().login.user.api_token;
        _data['user_panel_id'] = getState().login.user.id;
        _data['update_info'] = _info;

        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return updateUserOrder(_data).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch(  getAllFilteredUserOrders([
                ["deals.region_id","=", _data.region_id],
                ["deals.status","<",  8]
              ]) );

            return res;
        })
    } catch (error) {
        console.error(error);
    }

}
export const doneOrder = (_data) => async (dispatch, getState) => {
    try {
        _data['api_token'] = getState().login.user.api_token;
        _data['user_panel_id'] = getState().login.user.id;

        return doneUserOrder(_data).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch(getAllUserOrders());

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}




export const setOrder = (_val) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_ORDER,
            payload: _val,
        });
        storeData("SET_ORDER", _val);
    } catch (error) {
        console.error(error);
    }
};



export const addOrder = (_order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        let order = JSON.parse(JSON.stringify(_order));
        let user_id = null;

        order['api_token'] = getState().login.user.api_token;
        if (inArray(order.additional_work, 0)) order['is_hard_work'] = 1; else order['is_hard_work'] = 0;
        if (inArray(order.additional_work, 1)) order['is_assembly_disassembly'] = 1; else order['is_assembly_disassembly'] = 0;
        if (inArray(order.additional_work, 2)) order['is_package'] = 1; else order['is_package'] = 0;
        order['photos'] = order.photos.join(",");
        order['instrument_value'] = order.instrument_value.join(",");
        order['pack_value'] = order.pack_value.join(",");

        let str = "";
        if( order.parking_value > 0){
          str = " Паркинг: " + OrderConstant.PARKING_HEIGHT[order.parking_value].value;
        }
        if( order.demolition > 0){
            str = " Нужен Демонтаж ";
        }
        order['comment'] = order['comment'] + str;

        console.log("ADD", order)

        if (order.user_phone) {
            return filter(getState().login.user.api_token, "phone", order.user_phone).then((resFilter) => {
                if (resFilter.response) {
                    if (resFilter.response.length > 0) {
                        user_id = resFilter.response[0].id;
                        order['user_id'] = user_id;
                        return storeOrder(order).then((res) => {
                            console.log(res)
                            dispatch({
                                type: SET_API_REQUEST,
                                payload: false
                            });
                            if (res.status == 200) {
                                dispatch(getAllUserOrders());
                            }
                            return res
                        })
                    } else {
                        let _newUserData = {
                            api_token: getState().login.user.api_token,
                            id: getState().login.user.id,
                            is_confirm: 0,
                            is_worker: 0,
                            comment: "",
                            contact: "",
                            name: order.user_name,
                            phone: order.user_phone,
                            payment_info: null,
                            fact_contact: null,
                            car: null,
                            car_size: null,
                            car_carrying: null,
                            car_volume: null
                        };

                        return addWorkerUser(_newUserData).then((resUser) => {
                            console.log(resUser);
                            if (resUser.response) {
                                order['user_id'] = resUser.response.id;
                                return storeOrder(order).then((res) => {
                                    console.log(res)
                                    dispatch({
                                        type: SET_API_REQUEST,
                                        payload: false
                                    });
                                    if (res.status == 200) {
                                        dispatch(getAllUserOrders());
                                    }
                                    return res
                                })
                            } else {
                                alert("Ошибка создания ! Телефон заказчика заблокирован.")
                                return null;
                            }

                        })
                    }
                }
            })
        }




    } catch (error) {
        console.error(error);
    }

}


export const addPhoto = (_uri) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return addFile(_uri, 0).then((res) => {
            console.log(res)
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });

            return res
        })
    } catch (error) {
        console.error(error);
    }

}

export const getInvites = (_id) => async (dispatch, getState) => {
    try {


        let order = {};
        order['api_token'] = getState().login.user.api_token; 

        return getInvitesOrder(order, _id).then((res) => {

            if (res.status == 200) {
                dispatch({
                    type: SET_USER_INVITES,
                    payload: res.response
                });

            }

            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const removeInvite = (_oid, _id) => async (dispatch, getState) => {
    try {


        let order = {};
        order['api_token'] = getState().login.user.api_token; 
        order['deal_id'] = _oid;

        return removeUserInvite(order, _id).then((res) => {
            if (res.status == 200) dispatch(getInvites(_oid));
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}
