import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation, Navigate } from "react-router-dom";
import {
  getWorkers as getWorkersAction,
  getWorker as getWorkerAction,
  addWorker as addWorkerAction,
  editWorker as editWorkerAction,
  getWorkerRating as getWorkerRatingAction,
  getRegionsList as getRegionsListAction,
} from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { convertImages } from "../../services/Storage";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalUser from "../../components/modals/UiModalUser";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ClientsStyle.scss";

function OrgsPage(props) {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const userType = [
    "Админ",
    "Менеджер",
    "Наблюдатель",
    "Работник"
  ]

  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "ID" },
    { sort: true, title: "Регион" },
    { sort: true, title: "Тип аккаунта" },
    { sort: true, title: "Имя" },
    { sort: true, title: "ФИО" }, 
    { sort: true, title: "Телефон" },
    { sort: false, title: "Текущий Заказ" },
    { sort: true, title: "Рейтинг" },
    { sort: true, title: "Заявка" },
    { sort: true, title: "Пароль" },
    { sort: true, title: "Удалить" },
   
  ];
  const [tableListUsers, setTableListUsers] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    if (id) {
      props.getWorker(id).then((item) => {
        props.getWorkerRating(id).then((data) => {
          item.ratingList = data;
          setItemData(item);
        });
        setItemData(item);
        setModalActive(true);
      })
    }
  }, [location])

  useEffect(() => {
    if (props.login.user) {
      props.getWorkers();
      props.getRegionsList();
    }
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.usersWorker.map((item, index) => {
      arr.push([
        {
          title: clientsTitles[0].title,
          text: "",
          button: "open",
          openClick: () => {
            props.getWorkerRating(item.id).then((data) => {
              item.ratingList = data;
              setItemData(item);
            });
            setItemData(item);
            setModalActive(true);
          },
        },
        { title: clientsTitles[1].title, text: `#${item.id}` },
        {
          title: clientsTitles[2].title,
          text: item.region_id ? props.users.regionsList.filter((region) => { return region.id == item.region_id })[0]?.title : "Не указан",
        },
        {
          title: clientsTitles[3].title,
          color: item.type == 3 ? "blue" : "red",
          text: ` ${userType[item.type]}`
        },
        {
          title: clientsTitles[4].title,
          text: item.type == 3 ? item.name ? item.name : "Не указан" : item.email,
        },
        {
          title: clientsTitles[5].title,
          text:  item.full_name ? item.full_name : "Не указан"  
        },
        {
          title: clientsTitles[6].title,
          text: `+${item.phone}`,
        },
        {
          title: clientsTitles[7].title,
          text: item.deal ? `#${item.deal}` : "свободен",
          textLink: true,
          linkClick: () => { },
        },
        { title: clientsTitles[8].title, text: `${item.rating}` },
        {
          title: clientsTitles[9].title,
          color: item.is_confirm != 0 ? "blue" : "red",
          text: `${item.is_confirm == 0 ? "на рассмотрении" : "подтверждена"}`,
        },
        {
          title: clientsTitles[10].title,
          color:   "red",
          text: `${item.password}`,
        },
        

        {
          title: clientsTitles[11].title,
          text: "",
          button: "delete",
          openClick: () => {
            let data = item;
            data.is_del = 1;
            props.editWorker(data).then((res) => {
              if (res) {
                props.getWorkers();
              }
            });
          },
        },

      ]);
    });
    setTableListUsers(arr);
  }, [props.users.usersWorker]);


  function addImages(_id, _imagesList, _oldImages = []) {
    let _arr = [];
    _imagesList.map((item) => {
      let f = false;
      _oldImages.map((item2) => {
        if (item2 == item) f = true;
      });
      if (!f) _arr.push(item);
    });
    if (_oldImages.length == 0) _arr = _imagesList;
    return _arr;
  }

  return (
    <div id="page" className="orgs-page">
      <section className="orgs-info ui-grid">
        <div className="orgs-info-text">
          <UiPageText title="Бригады" />
        </div>
        <div className="orgs-info-button">
          <UiButtonColor
            color="primary"
            text="Новый работник"
            small={true}
            onClick={() => {
              setItemData(null);
              setTimeout(() => setModalActive(true), 100);
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список пользователей"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListUsers}
          search={searchLine}
        />
      </section>
      {/* modal user */}
      <UiModalUser
        modalActive={modalActive}
        modalClose={() => { 
          setModalActive(false);
          navigate("/orgs")
        } }
        data={itemData}
        onCreate={(data) => {
          props.addWorker(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getWorkers();
            } else {
              alert("Пользователь с таким телефоном уже создан")
            }
          });
        }}
        onEdit={(data) => {

          props.editWorker(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getWorkers();
            }
          });
        }}
      />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getWorkers: getWorkersAction,
  getWorker: getWorkerAction,
  addWorker: addWorkerAction,
  editWorker: editWorkerAction,
  getWorkerRating: getWorkerRatingAction,
  getRegionsList: getRegionsListAction,
})(OrgsPage);
