import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRatingList as getRatingListAction } from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDeal from "../../components/modals/UiModalDeal";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ClientsStyle.scss";

function ClientsRatingPage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: true, title: "ID" },
    { sort: true, title: "Имя Исполнителя" },
    { sort: true, title: "№ Заказа" },
    { sort: true, title: "Оценка" },
    { sort: false, title: "Отзыв" },
    { sort: false, title: "Дата" },
  ];
  const [tableListUsers, setTableListUsers] = useState([]);

  useEffect(() => {
    if (props.login.user) props.getRatingList();
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.ratingList.map((item, index) => {
      console.log(item);
      arr.push([
        { title: clientsTitles[0].title, text: `#${item.id}` },
        {
          title: clientsTitles[1].title,
          text: item.name ? item.name : `ID ${item.worker_id}`,
        },
        { title: clientsTitles[2].title, text: `#${item.deal_id}` },
        {
          title: clientsTitles[3].title,
          text: `${
            item.rating == 0
              ? ""
              : item.rating == 1
              ? "⭐"
              : item.rating == 2
              ? "⭐⭐"
              : item.rating == 3
              ? "⭐⭐⭐"
              : item.rating == 4
              ? "⭐⭐⭐⭐"
              : item.rating == 5
              ? "⭐⭐⭐⭐⭐"
              : ""
          }`,
        },
        { title: clientsTitles[4].title, text: item.text },
        {
          title: clientsTitles[5].title,
          text: `${formatDateDMY(item.updated_at)}`,
        },
      ]);
    });
    setTableListUsers(arr);
  }, [props.users.ratingList]);

  return (
    <div id="page" className="client-page">
      <section className="client-info ui-grid">
        <div className="client-info-text">
          <UiPageText title="Отзывы" />
        </div>
        <div className="client-info-button"></div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список отзывов"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={6}
          tableList={tableListUsers}
          search={searchLine}
        />
      </section>
      {/* modal deal */}
      <UiModalDeal modalActive={false} />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getRatingList: getRatingListAction,
})(ClientsRatingPage);
