import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrderConstant from "../../constants/OrderConstant";
import {
  getAllFilteredUserOrders as getAllFilteredUserOrdersAction,
  getAllUserOrders as getAllUserOrdersAction,
  updateOrder as updateOrderAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction,
  getInvites as getInvitesAction,
  removeInvite as removeInviteAction,
} from "../../redux/modules/order";
import {
  getDealComments as getDealCommentsAction,
  addComment as addCommentAction,
} from "../../redux/modules/comment";
import { getDealLogs as getDealLogsAction } from "../../redux/modules/logs";
import {
  filterUser as filterUserAction,
  getWorkers as getWorkersAction,
} from "../../redux/modules/login";
import {
  getUsersByPhone as getUsersByPhoneAction,
  getRegionsList as getRegionsListAction,

} from "../../redux/modules/users";
import { getPrice as getPriceAction } from "../../redux/modules/settings";

import UiBoard from "../../components/board/UiBoard";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDealAdd from "../../components/modals/UiModalDealAdd";
import UiModalDealEdit from "../../components/modals/UiModalDealEdit";
import UiPageText from "../../components/text/UiPageText";
import UiDropDown from "../../components/inputs/UiDropDown";

import "./HomeStyle.scss";

function HomePage(props) {
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [modalDealEditACtive, setModalDealEditActive] = useState(false);
  

  const [region, setRegion] = useState(1);
  const [regions, setRegiosn] = useState([]);
  const [orders, setOrders] = useState([]);
  const [deal, setDeal] = useState(null);

  const [dealComments, setDealComments] = useState([]);
  const [dealLogs, setDealLogs] = useState([]);

  useEffect(() => {
    if (props.login.user) {
      //props.getAllUserOrders();
      getOrders()
      props.getWorkers();
      props.getPrice(region);
      props.getRegionsList().then((data) => {
        setRegiosn(data)
      });
    }
  }, [props.login.user]);

  useEffect(() => {
    if (props.comment.commentsList) {
      setDealComments(props.comment.commentsList)
    }
  }, [props.comment.commentsList]);

  useEffect(() => {
    if (props.logs.logsList) {
      setDealLogs(props.logs.logsList)
    }
  }, [props.logs.logsList]);

  

  useEffect(() => { 
    getOrders()
    props.getPrice(region);
  }, [region]);

  function getOrders(){
    props.getAllFilteredUserOrders([
      ["deals.region_id","=", region],
      ["deals.status","<", OrderConstant.ORDER_STATUS_ARCHIVE]
    ]).then((data) => {
      setOrders(data)
    });
  }
  

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Сделки" />

        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Новый заказ"
            small={true}
            onClick={() => setModalDealAddActive(true)}
          />
        </div>
        <div className="home-info-button">
          <UiDropDown
            items={(regions).map((item) => { return { value: item.id, text: item.title } })}
            value={region}
            onChange={(val) => setRegion(val)}
          />
        </div>

      </section>
      <section className="home-content page-content">

        <UiBoard
          list={orders}
          comments={ dealComments }
          logs={dealLogs}
          userInvites={ props.order.ordersInvites ? props.order.ordersInvites : []}

          workers={props.login.usersWorker ? props.login.usersWorker : []}
          regionsList={props.users.regionsList ? props.users.regionsList : []}
          onChangeStatus={(item, status) => {
            let _item = item;
            _item.status = status;
            //done deal
            if (status == OrderConstant.ORDER_STATUS_ARCHIVE) {
              props.doneOrder({
                deal_id: item.id,
                worker_id: item.worker_id,
                user_id: item.user_id,
              });
            } else {
              props.updateOrder(_item, OrderConstant.UPDATE_INFO_NEW_STATUS).then(() => {
                getOrders()
              });
            }
          }}
          onChangeDealWorker={(item, wid) => {
            let _item = item;
            _item.worker_id = wid;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_NEW_WORKER).then(() => {
              getOrders()
            });
            props.getWorkers();
            props.getDealLogs(_item.id);
            
          }}
          onRequestDeal={(id) => {
            setDealComments([]);
            setDealLogs([]);
            props.getDealComments(id).then(() => {
              props.getDealLogs(id);
              props.getInvites(id);
            })
         
          }}
          onAddComment={(data) => props.addComment(data)}
          onChangeDealPrice={(item, price) => {
            let _item = item;
            _item.price = price;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_PRICE).then(() => {
              getOrders()
            });
            props.getDealLogs(_item.id);
          }}
          onChangeDealText={(item, text) => {
            let _item = item;
            _item.text = text;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
              getOrders()
            });
            props.getDealLogs(_item.id);
          }}

          onChangeDealDate={(item, text) => {
            let _item = item;
            _item.date = text;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
              getOrders()
            });
            props.getDealLogs(_item.id);
          }}

          onChangeDealTime={(item, text) => {
            let _item = item;
            _item.time = text;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
              getOrders()
            });
            props.getDealLogs(_item.id);
          }}


          onRemoveInvite={(item, id) => { 
            props.removeInvite(item.id, id).then(() => {
              getOrders()
            });
          }}
          onChangeAutoSelectWorker={(item, val) => {
            let _item = item;
            _item.manual_select_worker = val; 
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
              getOrders()
            });
            props.getDealLogs(_item.id);

           
          }}
          onChangeTime={(item, id) => {
            let _item = item;
            _item.text = item.text;
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
              getOrders()
            });
            props.getDealLogs(_item.id);

           
          }}
          onShowEdit={(item) => {
            setModalDealEditActive(true);
            setDeal(item)
           
          }}
        />
      </section>
      {/* modal deal add */}
      <UiModalDealAdd
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          data.region_id = region;
          props.addOrder(data).then(() => {
            setModalDealAddActive(false);
            getOrders()
          });
        }}
      />
      {/* modal deal edit */}
      <UiModalDealEdit
        modalActive={modalDealEditACtive}
        modalClose={() => setModalDealEditActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        deal={deal}
        onEdit={(data) => {
          props.updateOrder(data, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
            setModalDealEditActive(false);
            getOrders()
          });
        
        }}
      />

    </div>
  );
}

export default connect(
  ({ order, login, comment, logs, users, settings }) => ({
    order,
    login,
    comment,
    logs,
    users,
    settings,
  }),
  {
    getAllFilteredUserOrders: getAllFilteredUserOrdersAction,
    getAllUserOrders: getAllUserOrdersAction,
    updateOrder: updateOrderAction,
    addOrder: addOrderAction,
    doneOrder: doneOrderAction,
    getInvites: getInvitesAction,
    removeInvite: removeInviteAction,

    getDealComments: getDealCommentsAction,
    addComment: addCommentAction,
    getDealLogs: getDealLogsAction,
    filterUser: filterUserAction,
    getWorkers: getWorkersAction,
    getUsersByPhone: getUsersByPhoneAction,
    getPrice: getPriceAction,
    getRegionsList: getRegionsListAction,
  }
)(HomePage);
