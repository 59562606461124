import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OrderConstant from "../../constants/OrderConstant";

import { sumItemValue, inArrayValueByKey } from "../../utilities/array";
import { formatDateDMY, formatDatePlusDayDMY } from "../../utilities/date";

import UiButtonColor from "../button/UiButtonColor";
import UiCheckButton from "../check/UiCheckButton";
import UiModalDeal from "../modals/UiModalDeal";
import UiBoardButton from "./cards/UiBoardButton";

import UiBoardCard from "./cards/UiBoardCard";
import UiBoardTitle from "./cards/UiBoardTitle";

import "./UiBoard.scss";

export default function UiBoard(props) {
  const [dealOpen, setDealOpen] = useState(false);
  const [deal, setDeal] = useState(null);

  const [state, setState] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [ordersList, setOrdersList] = useState([]);

  /**
   * Filters const
   * 0 1 2
   * all today tomorrow
   */
  const [filters, setFilters] = useState(0);

  /**
   * reaorder dnd list
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns result
   */

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  function onDragEnd(result) {
    ordersList.map((item) => {
      if (item.id == result.draggableId.split("-")[0])
        props.onChangeStatus(item, result.destination.droppableId);
    });
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      //setState(newState.filter(group => group.length));
      setState(newState);
    }
  }

  useEffect(() => {
    if (props.list) {
      let arr = [[], [], [], [], [], [], [], [], [], [], [], []];
      props.list.map((item) => {
        var f = false;
        if (filters == 0) {
          f = true;
        } else if (
          filters == 1 &&
          formatDateDMY(item.date) == formatDatePlusDayDMY()
        ) {
          f = true;
        } else if (
          filters == 2 &&
          formatDateDMY(item.date) == formatDatePlusDayDMY(null, 1)
        ) {
          f = true;
        }

        if (f) {
          arr[item.status].push({
            id: `${item.id}-${new Date().getTime()}`,
            orderId: item.id,
            title: `${item.order_name ? item.order_name : item.id}`,
            time: `${item.date} ${item.time}`,
            price: item.price,
            text: [
              `${item.without_car == 1? 'ТОЛЬКО ГРУЗЧИКИ' : ''}`,
              `Название: ${item.order_name ? item.order_name : "Не указан"}`,
              `Заказчик: ${item.name ? item.name : "Не указан"} `,
              `Исполнитель: ${
                inArrayValueByKey(props.workers, "id", item.worker_id)
                  ? inArrayValueByKey(props.workers, "id", item.worker_id).name
                  : "Не назначен"
              }`,
              `${item.region_id ? props.regionsList.filter((region) => {return region.id == item.region_id })[0]?.title: ""}`,
            ],
            members: [],
            deal: item,
          });
        }
        if (deal !== null) if (deal.id == item.id) setDeal(deal);
      });
      setOrdersList(props.list);
      setState(arr);
    }
  }, [props.list, props.workers, filters]);

  return (
    <div className="board-wrap">
      {/**
       * filters
       * colors: red, pink, purple, violet, primary, blue, sky, cyan, teal, grass, green, lime, yellow, amber, orange, brick, gray, dark
       */}
      <div className="board-filters">
        <UiCheckButton
          color="primary"
          text="Все заказы"
          value={filters == 0}
          onChange={(val) => setFilters(0)}
        />
        <UiCheckButton
          color="orange"
          text="Заказы на Сегодня"
          value={filters == 1}
          onChange={(val) => setFilters(val ? 1 : 0)}
        />
        <UiCheckButton
          color="purple"
          text="Заказы на Завтра"
          value={filters == 2}
          onChange={(val) => setFilters(val ? 2 : 0)}
        />
      </div>
      <div className="board-scroll">
        <div className="board">
          <DragDropContext onDragEnd={onDragEnd}>
            {state.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    className="board-column-scroll"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="board-column">
                      <UiBoardTitle
                        color={OrderConstant.ORDER_TYPES_COLORS[ind]}
                        title={OrderConstant.ORDER_TYPES_NAMES[ind]}
                        num={el.length}
                        sum={sumItemValue(el, "price")}
                      />
                      {el.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="board-cards-list"
                            >
                              <UiBoardCard
                                onClick={() => {
                                  props.onRequestDeal(item.orderId);
                                  setDeal(item.deal);
                                  setDealOpen(true);
                                }}
                                members={[]}
                                title={item.title}
                                time={item.time}
                                text={item.text}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
          {/*<button className="board-add-column">Добавить этап</button>*/}
        </div>
        {/*
        <div className="board-close">
          <p>Выполнено</p>
        </div>
        */}
      </div>
      {/**
       * modals
       */}
      <UiModalDeal
        modalActive={dealOpen}
        modalClose={() => setDealOpen(false)}
        deal={deal}
        comments={props.comments}
        logs={props.logs}
        workers={props.workers}
        userInvite={props.userInvites}
        onAddComment={(data) => props.onAddComment(data)}
        onAddWorker={(id) => props.onChangeDealWorker(deal, id)}
        onEditPrice={(price) => props.onChangeDealPrice(deal, price)}
        onEditText={(text) => props.onChangeDealText(deal, text)}
        onEditDate={(text) => props.onChangeDealDate(deal, text)}
        onEditTime={(text) => props.onChangeDealTime(deal, text)}

        onRemoveInvite={(id) => props.onRemoveInvite(deal, id)}
        onChangeTime={() => props.onChangeTime(deal)}
        onChangeAutoSelectWorker={(id) => props.onChangeAutoSelectWorker(deal, id)}
        onShowEdit={() => props.onShowEdit(deal)}
      />
    </div>
  );
}
