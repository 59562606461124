import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import ClientsPage from "../pages/clients/ClientsPage";
import ClientsRatingPage from "../pages/clients/ClientsRatingPage";
import OrgsPage from "../pages/orgs/OrgsPage";
import ArchivePage from "../pages/archive/ArchivePage";
import SettingPage from "../pages/setting/SettingPage";
import StatisticPage from "../pages/statistic/StatisticPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute>
            <ClientsPage />
          </ProtectedRoute>
        }
      />
      
      <Route
        path="/orgs"
        element={
          <ProtectedRoute>
            <OrgsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orgs/:id"
        element={
          <ProtectedRoute>
            <OrgsPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/archive"
        element={
          <ProtectedRoute>
            <ArchivePage />
          </ProtectedRoute>
        }
      />
       <Route
        path="/clients-rating"
        element={
          <ProtectedRoute>
            <ClientsRatingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/setting"
        element={
          <ProtectedRoute>
            <SettingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/statistic"
        element={
          <ProtectedRoute>
            <StatisticPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default AppNavigator;
