import React, { Component } from "react";

import { ReactComponent as SvgPhotoCamera } from "../../assets/images/ui/photo-camera.svg";
import loaderImage from "../../assets/images/ui/loader.gif";

import "./UiCardAddPhoto.scss";

class UiCardAddPhoto extends Component {
  render() {
    return (
      <div className="card-add-photo-wrap">
        {this.props.image && !this.props.editable ? (
          this.props.isLoad ? (
            <div className="card-add-photo-image-loading">
              <img alt="loader" src={loaderImage} />
            </div>
          ) : (
            <div
              className="card-add-photo-image"
              style={{
                width: "100%",
                backgroundImage: "url(" + this.props.image + ")",
              }}
            >
              <small style={{backgroundColor: "white", cursor: "pointer"}} onClick={() => window.open(this.props.image, "_blank")}>СКАЧАТЬ</small>
              <button onClick={() => this.props.remove()}>x</button>
            </div>
          )
        ) : (
          <label
            className="card-add-photo"
            style={{ backgroundImage: "url(" + this.props.image + ")" }}
          >
            {this.props.image && !this.props.editable ? null : this.props
                .isLoad ? (
              <div className="card-add-photo-image-loading">
                <img alt="loader" src={loaderImage} />
              </div>
            ) : (
              <div
                className={
                  "card-add-photo-overlay" +
                  (this.props.editable && this.props.image ? " editable" : "")
                }
              >
                {this.props.small ? null : (
                  <SvgPhotoCamera className="card-add-photo-icon" />
                )}
                <p style={this.props.small ? { fontSize: 20 } : null}>
                  +{this.props.small ? null : " Добавить фото"}
                </p>
              </div>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                this.props.upload(e.target.files[0]);
                e.target.value = '';
              }}
            />
          </label>
        )}
      </div>
    );
  }
}
export default UiCardAddPhoto;
