import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./UiModal.scss";

const UiModal = ({ children, name, show, onClose }) => {
  const [topScroll, setTopScroll] = useState(0);

  useEffect(() => {
    if (show) {
      _openModal();
    } else {
      _closeModal();
    }
  }, []);

  const _openModal = () => {
    let top = window.pageYOffset || document.getElementById("page").scrollTop;

    setTopScroll(top);

    let element = document.getElementById("page");
    if (element) {
      element.classList.toggle("page-lock");
      ReactDOM.findDOMNode(element).style.top = -top + "px";
    }
  };

  const _closeModal = () => {
    let element = document.getElementById("page");
    if (element) {
      element.classList.toggle("page-lock");
      window.scrollTo(0, topScroll);
    }
  };

  return (
    <div className={"modal-wrap" + (show ? " show" : " hide")}>
      {show ? (
        <div className={"modal modal-overlay " + " " + name} id="modal" onClick={(e) => {
          { 
           // if ((e.target.className).includes("modal-overlay") && onClose) onClose()
          }
        }}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default UiModal;
