import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllFilteredUserOrders as getAllFilteredUserOrdersAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction,
  getInvites as getInvitesAction,
  updateOrder as updateOrderAction,
} from "../../redux/modules/order";
import { getWorkers as getWorkersAction } from "../../redux/modules/login";
import { getDealComments as getDealCommentsAction } from "../../redux/modules/comment";
import { getDealLogs as getDealLogsAction } from "../../redux/modules/logs";
import { formatDateDMY, formatDateHM } from "../../utilities/date";

import OrderConstant from "../../constants/OrderConstant";

import UiInputSearch from "../../components/inputs/UiInputSearch";

import UiModalDeal from "../../components/modals/UiModalDeal";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ArchiveStyle.scss";
import { safelyParseJSON } from "../../services/Storage";

function ArchivePage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "ID" },
    { sort: true, title: "Телефон заказчика" },
    { sort: true, title: "Тип работы" },
    { sort: true, title: "Адреса" },
    { sort: true, title: "Кол-во грузчиков" },
    { sort: true, title: "Цена" },
    { sort: true, title: "Дата заказа" },
    { sort: true, title: "Дата создания заказа" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  function load(){
    props.getAllFilteredUserOrders([
      ["deals.status", "=", OrderConstant.ORDER_STATUS_ARCHIVE],
    ]);
  }

  useEffect(() => {
    if (props.login.user) {
      props.getWorkers();
      load();
    }
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.order.ordersList.map((item, index) => {
      arr.push([
        {
          title: clientsTitles[0].title,
          text: "",
          button: "open",
          openClick: () => {
            setItemData(item);
            props.getDealComments(item.id);
            props.getDealLogs(item.id);
            props.getInvites(item.id);
            setModalActive(true);
          },
        },
        {
          title: clientsTitles[1].title,
          text: "вернуть в работу",
          textLink: true,
          linkClick: () => {
            let _item = item;
            _item.status = 0;
            //done deal 
            props.updateOrder(_item, OrderConstant.UPDATE_INFO_NEW_STATUS)
              .then(() => {
                load();
              });
          },
        },
        { title: clientsTitles[2].title, text: `#${item.id}` },
        { title: clientsTitles[3].title, text: `+${item.phone}` },
        {
          title: clientsTitles[4].title,
          text: OrderConstant.MOVING_TYPE[item.moving_types].text,
        },
        {
          title: clientsTitles[5].title,
          text: renderAddress(item.address_list),
        },

        {
          title: clientsTitles[6].title,
          text: item.movers,
        },
        {
          title: clientsTitles[7].title,
          text: `${item.price} р`,
        },
        {
          title: clientsTitles[8].title,
          text: `${formatDateDMY(item.date)} ${item.time}`,
        },
        {
          title: clientsTitles[9].title,
          text: `${formatDateDMY(item.created_at)} ${formatDateHM(
            item.created_at
          )}`,
        },
      ]);
    });
    setTableListOrders(arr);
  }, [props.order.ordersList]);

  function search(val) {
    if (val == "") {
      load();
    } else if (val.length > 2 && val.includes("#")) {
      props.getAllFilteredUserOrders([["deals.id", "=", val.replace("#", "")]]);
    } else {
      setSearchLine(val);
    }
  }

  function renderAddress(list) {
    let arr = safelyParseJSON(list);
    return arr
      .map((item) => {
        return item.address;
      })
      .join(" -> ");
  }

  return (
    <div id="page" className="archive-page">
      <section className="archive-info ui-grid">
        <div className="archive-info-text">
          <UiPageText title="Архив заказов" />
        </div>
        <div className="archive-info-button"></div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список выполненных заказов"
          placeholder="Поиск..."
          onChange={(val) => search(val)}
        />
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
      {/* modal deal */}

      <UiModalDeal
        modalActive={modalActive}
        modalClose={() => setModalActive(false)}
        deal={itemData}
        comments={props.comment.commentsList ? props.comment.commentsList : []}
        logs={props.logs.logsList ? props.logs.logsList : []}
        userInvites={props.order.ordersInvites ? props.order.ordersInvites : []}
        workers={props.login.usersWorker ? props.login.usersWorker : []}
      />
    </div>
  );
}

export default connect(
  ({ login, order, comment, logs }) => ({ login, order, comment, logs }),
  {
    getAllFilteredUserOrders: getAllFilteredUserOrdersAction,
    addOrder: addOrderAction,
    doneOrder: doneOrderAction,
    updateOrder: updateOrderAction,
    getWorkers: getWorkersAction,
    getDealComments: getDealCommentsAction,
    getDealLogs: getDealLogsAction,
    getInvites: getInvitesAction,
  }
)(ArchivePage);
