import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getWorkers as getWorkersAction,
  addWorker as addWorkerAction,
  editWorker as editWorkerAction,
} from "../../redux/modules/users";

import UiPageText from "../../components/text/UiPageText";

import "./StatisticStyle.scss";

function StatisticPage(props) {
  return (
    <div id="page" className="statistic-page">
      <section className="statistic-info ui-grid">
        <div className="statistic-info-text">
          <UiPageText title="Статистика" />
        </div>
      </section>
      <section className="page-content">
        <p>Статистика по заказам</p>
      </section>
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getWorkers: getWorkersAction,
  addWorker: addWorkerAction,
  editWorker: editWorkerAction,
})(StatisticPage);
