import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUsersList as getUsersListAction,
  editWorker as editWorkerAction,
  getRemovedUsersList as getRemovedUsersListAction,
  getRegionsList as getRegionsListAction,
} from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { convertImages } from "../../services/Storage";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalUser from "../../components/modals/UiModalUser";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ClientsStyle.scss";

function ClientsPage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: true, title: "ID" },
    { sort: true, title: "Регион" },
    { sort: true, title: "Имя" },
    { sort: true, title: "Телефон" },
    { sort: true, title: "Кол-во заказов" },
    { sort: true, title: "Дата регистрации" },
    { sort: false, title: "Сделать работником" },
    { sort: true, title: "Удалить" },
  ];
  const clientsRemTitles = [
    { sort: true, title: "ID" },
    { sort: true, title: "Имя" },
    { sort: true, title: "Телефон" },
    { sort: true, title: "Восстановить" },
  ];
  const [tableListUsers, setTableListUsers] = useState([]);
  const [tableRemListUsers, setRemTableListUsers] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    if (props.login.user) {
      props.getUsersList();
      props.getRemovedUsersList();
      props.getRegionsList();
    }
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.usersList.map((item, index) => {
      arr.push([
        { title: clientsTitles[0].title, text: `#${item.id}` },
        {
          title: clientsTitles[1].title,
          text: item.region_id ? props.users.regionsList.filter((region) => {return region.id == item.region_id })[0]?.title  : "Не указан",
        },
        {
          title: clientsTitles[2].title,
          text: item.name ? item.name : "Не указан",
        },
        {
          title: clientsTitles[3].title,
          text: `+${item.phone}`,
        },
        { title: clientsTitles[4].title, text: `${item.deals_count}` },
        {
          title: clientsTitles[5].title,
          text: `${formatDateDMY(item.created_at)} ${formatDateHM(
            item.created_at
          )}`,
        },
        {
          title: clientsTitles[6].title,
          color: "red",
          text: `Сделать бригадиром`,
          textLink: true,
          linkClick: () => {
            let allow = window.confirm("Вы уверены что хотите сделать это клиента БРИГАДИРОМ");
            if(allow){
              let data = item;
              data.is_confirm = 1;
              data.is_worker = 1;
              props.editWorker(data).then((res) => {
                if (res) { 
                  props.getRemovedUsersList();
                }
              });
            }
            
          } 
        },
        {
          button: "delete",
          openClick: () => {
            let data = item;
            data.is_del = 1;
            props.editWorker(data).then((res) => {
              if (res) { 
                props.getRemovedUsersList();
              }
            });
          } 
        },
      ]);
    });
    setTableListUsers(arr);
  }, [props.users.usersList]);

  useEffect(() => {
    var arr = [];
    props.users.usersWorker.map((item, index) => {
      arr.push([
        { title: clientsTitles[0].title, text: `#${item.id}` },
        {
          title: clientsTitles[1].title,
          text: item.name ? item.name : "Не указан",
        },
        {
          title: clientsTitles[2].title,
          text: `+${item.phone}`,
        }, 
        {
          button: "edit",
          openClick: () => {
            let data = item;
            data.is_del = 0;
            props.editWorker(data).then((res) => {
              if (res) { 
                props.getRemovedUsersList();
              }
            });
          } 
        },
      ]);
    });
    setRemTableListUsers(arr);
  }, [props.users.usersWorker]);

  function addImages(_id, _imagesList, _oldImages = []) {
    let _arr = [];
    _imagesList.map((item) => {
      let f = false;
      _oldImages.map((item2) => {
        if (item2 == item) f = true;
      });
      if (!f) _arr.push(item);
    });
    if (_oldImages.length == 0) _arr = _imagesList;
    return _arr;
  }

  return (
    <div id="page" className="client-page">
      <section className="client-info ui-grid">
        <div className="client-info-text">
          <UiPageText title="Клиенты" />
        </div>
        <div className="client-info-button">
          <UiButtonColor
            color="primary"
            text="Новый клиент"
            small={true}
            onClick={() => {
              setItemData(null);
              setTimeout(() => setModalActive(true), 100);
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список пользователей"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={8}
          tableList={tableListUsers}
          search={searchLine}
        />

      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список удаленных пользователей"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive
          titleList={clientsRemTitles}
          grid={4}
          tableList={tableRemListUsers}
          search={searchLine}
        />
      </section>
      {/* modal user */}
      <UiModalUser
        modalActive={modalActive}
        modalClose={() => setModalActive(false)}
        data={itemData}
        onCreate={(data) => {
          props.addWorker(data).then((res) => {
            if (res) {
              setModalActive(false);
            }
          });
        }}
        onEdit={(data) => {
          data.image = addImages(
            data.id,
            data.image,
            convertImages(itemData.images)
          );
          console.log(data);
          props.editWorker(data).then((res) => {
            if (res) {
              setModalActive(false);
              props.getWorkers();
            }
          });
        }}
      />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getUsersList: getUsersListAction,
  getRemovedUsersList: getRemovedUsersListAction,
  editWorker: editWorkerAction,
  getRegionsList: getRegionsListAction,
})(ClientsPage);
