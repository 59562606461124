import * as React from "react";
import { useLocation } from "react-router-dom";
import configureStore from "./redux/store";
import { Provider } from "react-redux";

import UiAlert from "./components/modals/UiAlert";
import UiAnimatedAppLoader from "./components/main/UiAnimatedAppLoader";
import UiDialog from "./components/modals/UiDialog";
import UiHeader from "./components/header/UiHeader";
import UiLoader from "./components/modals/UiLoader";
import UiMenu from "./components/menu/UiMenu";

import AppNavigator from "./navigation/AppNavigator";

import "./styles/app.scss";

function App() {
  const store = configureStore();

  let location = useLocation().pathname;
  let uiVisible = !location.includes("/login");

  return (
    <Provider store={store}>
      <UiAnimatedAppLoader />
      <div className="app" id="app">
        {uiVisible ? <UiHeader /> : null}
        <div
          className={
            "app-container ui-grid" + (!uiVisible ? " full-container" : "")
          }
        >
          {uiVisible ? <UiMenu /> : null}
          <div className="app-content">
            <AppNavigator />
          </div>
        </div>
      </div>

      {/* modals */}
      <UiAlert />
      <UiLoader />
    </Provider>
  );
}

export default App;
