export default {
    STORE_KEY: 'a56z0fzrNpl^2',
    //Dev
    //HOST_URL: 'http://ant.devkot.ru/',
    //API_URL: 'http://ant.devkot.ru/server/public/api/v1',
    //PUBLIC_URL: 'http://ant.devkot.ru/server/public/uploads/',

    //Prod
    HOST_URL: 'http://crm.moving-ants.ru/',
    API_URL: 'http://crm.moving-ants.ru/server/public/api/v1',
    PUBLIC_URL: 'http://crm.moving-ants.ru/server/public/uploads/',
    //STORAGE_URL: '',
   
    getHeaders: function () {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }
  };