import React, { useState, useEffect } from "react";

import { ReactComponent as SvgAdd } from "../../assets/images/ui/plus.svg";
import { ReactComponent as SvgSend } from "../../assets/images/ui/paper-plane.svg";

import "./UiSendMsg.scss";

export default function UiSendMsg(props) {
  const [text, setText] = useState("");

 
  return (
    <div className="send-msg">
      {/*<button className="send-msg-add-image">
        <SvgAdd className="send-msg-add-image-icon" />
  </button>*/}
      <input
        className="send-msg-input"
        id="UiSendMsg"
        placeholder="Сообщение.."
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            props.onAddPress(text);
            document.getElementById("UiSendMsg").value = "";
            setText("");
          }
        }}
      />
      <button
        className="send-msg-button"
        onClick={() => {
          props.onAddPress(text);
          document.getElementById("UiSendMsg").value = "";
          setText("");
        }}
      >
        <SvgSend className="send-msg-button-icon" />
      </button>
    </div>
  );
}
